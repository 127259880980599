<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">코스 그룹 리스트</h4>
              <p class="card-title-desc">
                현재까지 등록 된 코스 그룹을 보여줍니다.
              </p>
              <div class="mb-3">
                <router-link to="/course/group_add">
                  <button class="btn btn-primary btn-sm">등록</button>
                </router-link>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <colgroup>
                    <col width="100" />
                    <col width="*" />
                    <col width="100" />
                    <col width="100" />
                    <col width="100" />
                    <col width="150" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>그룹명</th>
                      <th>강의수</th>
                      <th>노출순서</th>
                      <th>오픈여부</th>
                      <th>관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in courseGroup" :key="item.idx">
                      <td scope="row">{{ pageStartIndex - i }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.cnt }}</td>
                      <td>{{ item.sort }}</td>
                      <td>{{ item.openYn }}</td>
                      <td>
                        <b-button
                          variant="danger"
                          size="sm"
                          class="me-1"
                          @click="deleteGroup(item.idx)"
                          >삭제</b-button
                        >
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="$router.push('/course/group_modify/' + item.idx)"
                          >수정</b-button
                        >
                      </td>
                    </tr>
                    <tr v-if="courseGroup.length === 0">
                      <td colspan="7" class="tac">데이터가 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end table-response -->
              <b-pagination
                v-model="pageData.currentPage"
                :total-rows="pageData.totalCnt"
                :per-page="pageData.perPage"
                align="center"
                aria-controls="my-table"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Course',
    meta: [{name: 'description', content: appConfig.description}]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: 'Group Lists',
      items: [
        {
          text: 'Course',
          href: '/'
        },
        {
          text: 'Group',
          href: '/'
        },
        {
          text: 'Group Lists',
          active: true
        }
      ],
      courseGroup: [],
      pageStartIndex: 0,
      pageData: {
        currentPage: 1, // 현재 페이지
        perPage: 10, // 페이지 초기 갯수
        totalCnt: 0 // 전체 갯수
      }
    };
  },
  mounted() {
    this.initCourseGroupList();
  },
  watch: {
    'pageData.currentPage'() {
      this.initCourseGroupList();
    }
  },
  methods: {
    async initCourseGroupList() {
      this.$store
        .dispatch('course/course_group_list', {
          page: this.pageData.currentPage,
          perPage: this.pageData.perPage
        })
        .then(res => {
          this.courseGroup = res.list;
          this.pageData.totalCnt = res.total;
          this.pageStartIndex =
            this.pageData.totalCnt - (this.pageData.currentPage - 1) * this.pageData.perPage;
        });
    },
    test() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            // ... do something
          }
        }
      });
    },
    deleteGroup(idx) {
      this.$confirm({
        message: `코스 그룹을 삭제하시겠습니까?`,
        button: {
          no: '취소',
          yes: '삭제'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: async confirm => {
          if (confirm) {
            // ... do something
            await this.$store
              .dispatch('course/deleteCourseGroup', {
                id: idx
              })
              .then(res => {
                this.$confirm({
                  title: res.result ? 'Success' : 'Error',
                  message: res.message,
                  button: {
                    yes: '확인'
                  }
                });

                if (res.result) {
                  this.initCourseGroupList();
                }
              });
          }
        }
      });
    }
  }
};
</script>
<style scoped>
table th,
table td {
  text-align: center;
}
</style>
